$color_red: #AC5E5F;
$gradientLightBlue:linear-gradient(to top, #6a85b6 0%, #bac8e0 100%);

//$primary:#4777DA;
//$primary:#D74F50;

//$primary:#AA403F;

$primary:#5463FF;
$third:#64B2AB;
@import "~bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');
// ###################### PAginator #########
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&display=swap');
body{
  -webkit-print-color-adjust: exact !important;
}

.bg-darker{
  background-color: #293369;
}

.bg-third{
  background-color: $third !important;
}

.border-third{
  border: 2px solid $third;
}


.text-blue{
  color:blue;
}

.map_kennel{
  max-height:90vh;
  min-width:100%;
  @media (max-width: 450px){
    width:600px;
    max-height: 700px;
  }
}
.cadre_map{
  width:70%;
  @media (max-width: 450px){
    overflow-x: scroll;
    overflow-y: scroll;
    width: 100%;
  }
}

.map_panneau_opt{
    width: 30%;
  @media (max-width: 450px){
    width:100%;

  }
}

.guide_help{
  position:fixed;
  bottom:0;
  right:0;
  margin-right:60px;
  min-width:120px;
  height:60px;
  z-index:9999999;
  cursor:pointer;
}

.badge_pdf{
font-size:10px;
border-radius: 3px;

padding-top: 1px;
padding-left: 2px;
padding-right: 2px;
min-width: 47px;
max-width: 47px;
overflow-x: hidden;
text-align: center;

}



@-moz-document url-prefix() {
  .guide-title{
    margin-right:50px !important;
  }

  .infos-chien{
    font-size:10px !important;
  }
}

@media print {

.date_day{
page-break-after:always;
}

}

.bg-yellow{
background-color: #ffdf7e;

}

.collapse li{
  list-style: none;

}

.perso-tab .nav-link{
  transition: 0.5 ease-in-out;
}

.perso-tab .active{
  font-weight: 600 !important;
  border-color: transparent !important;
  color:white !important;
  background-color: $primary !important;
  border-radius: 10px;
}

.checkmark{
  width:60px;
  height:60px;
}

.w-100-div>div{
  min-width:50% !important;
}

.opacity-5{
  opacity: 0.5;
}
/*LOADER*/
.loader {
    --path: #4777DA;
    --dot: #4777DA6A;
    --duration: 2s;
    width: 44px;
    height: 44px;
    position: relative;
    &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        display: block;
        background: var(--dot);
        top: 37px;
        left: 19px;
        transform: translate(-18px, -18px);
        animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }
    svg {
        display: block;
        width: 100%;
        height: 100%;
        rect,
        polygon,
        circle {
            fill: none;
            stroke: var(--path);
            stroke-width: 10px;
            stroke-linejoin: round;
            stroke-linecap: round;
        }
        polygon {
            stroke-dasharray: 145 (221 - 145) 145 (221 - 145);
            stroke-dashoffset: 0;
            animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
        }
        rect {
            stroke-dasharray: (256 / 4 * 3) (256 / 4) (256 / 4 * 3) (256 / 4);
            stroke-dashoffset: 0;
            animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
        }
        circle {
            stroke-dasharray: (200 / 4 * 3) (200 / 4) (200 / 4 * 3) (200 / 4);
            stroke-dashoffset: 75;
            animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
        }
    }
    &.triangle {
        width: 48px;
        &:before {
            left: 21px;
            transform: translate(-10px, -18px);
            animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
        }
    }
}

@keyframes pathTriangle {
    33% {
        stroke-dashoffset: 74;
    }
    66% {
        stroke-dashoffset: 147;
    }
    100% {
        stroke-dashoffset: 221;
    }
}

@keyframes dotTriangle {
    33% {
        transform: translate(0, 0);
    }
    66% {
        transform: translate(10px, -18px);
    }
    100% {
        transform: translate(-10px, -18px);
    }
}

@keyframes pathRect {
    25% {
        stroke-dashoffset: 64;
    }
    50% {
        stroke-dashoffset: 128;
    }
    75% {
        stroke-dashoffset: 192;
    }
    100% {
        stroke-dashoffset: 256;
    }
}

@keyframes dotRect {
    25% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(18px, -18px);
    }
    75% {
        transform: translate(0, -36px);
    }
    100% {
        transform: translate(-18px, -18px);
    }
}

@keyframes pathCircle {
    25% {
        stroke-dashoffset: 125;
    }
    50% {
        stroke-dashoffset: 175;
    }
    75% {
        stroke-dashoffset: 225;
    }
    100% {
        stroke-dashoffset: 275;
    }
}

.loader {
    display: inline-block;
    margin: 0 16px;
}

html {
    -webkit-font-smoothing: antialiased;
}

* {
    box-sizing: border-box;
    &:before,
    &:after {
        box-sizing: border-box;
    }
}

/* FIN LOADER*/


.opacity-8{
  opacity: 0.8;
}

.w-lg-50{
  @media (min-width: 992px){
    width:50% !important;
  }
}

.w-md-75{
  @media (min-width: 768px){
    width:65% !important;
  }
}

.w-team-added{
  @media (min-width: 992px){
    width:45% !important;
    min-width:150px;
  }
  @media (min-width: 768px){
    width:auto !important;
  }
  height:auto;
}

.w-team-added-container{
  @media (min-width: 992px){
    max-width:35% !important;
  }
  @media (min-width: 768px){
    width:100% !important;
  }
}



.custom-pagin span {
    border:none;
    color:grey;
    font-weight: 100;
    background-color: transparent;
    padding:10px;
    border: 1px solid transparent;
    border-radius: 0px;
    cursor: pointer;
    a{
      text-decoration: none;
    }
    &:hover {
      background-color: transparent;
      border-bottom: 2px solid $primary;
      color:$primary;
      font-weight: bold;
    }
}

.w-md-100{
    @media (min-width: 776px){
      width:100% !important;
    }
}

.custom-pagin  span{

    &.current {
      border-bottom: 2px solid $primary;
      color:$primary;
      font-weight: bold;
    }
    &.disabled {
      color:$primary;
    }
}


// ####################@

body{
  background-color: #F2F2F2;
}

.bg-lightgrey{
  background-color: #F2F2F2;
}

.bg-none{
  background-color: transparent !important;
  border-color: transparent !important;
}

.bg-blue{
  background-color: #A8C9F0;
}

.bg-header{
  background-image: url('/header.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}

.bg-mushin{
  background-image: url('/deco_mushin.svg');
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: right;
  @media (max-width: 776px){
    background-size: 60%;
  }
}

.bg-plan{
  background-image: url('/map_background.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: right;
  @media (max-width: 776px){
    background-size: 100%;
  }
}

.fixed-left{
	position: fixed;
	height:100vh;
}

.label-space label{
  margin-right: 10px;
}

.bg-un{
  background-color: #58272D;
}

.bg-deux{
  background-color: #DD7571;
}

.bg-trois{
  background-color: #222530;
}

.bg-six{
  background-color: #718499;
}

.min-h-60{
  min-height: 60px;
}
.w-min-100{
  min-width: 100%;
}

.medium-text{
  font-size: 0.5rem;
}

.bg-s{
  background-color: #DD7571A0;
  font-weight: 700;
}
.bg-sl{
  background-color: #DD7571A2;
  background-size: 20px 20px;
  background-image: repeating-linear-gradient(45deg, #9400576A 0, #9400576A 2px, #DD75714A 0, #DD75714A 50%);
  font-weight: 700;
}


.z-index{
  z-index: 999;
}

.bg-m{
  background-color: #438cd5;
  font-weight: 700;
}
.bg-ml{
  background-color: #438cd5A5;
  background-size: 20px 20px;
  background-image: repeating-linear-gradient(45deg, #FFFFFF21 0, #FFFFFF21 2px, #438cd5A2 0, #438cd5A2 50%);
  font-weight: 700;
}


.bg-l{
  background-color: #168A73;
  font-weight: 700;
}

.bg-xl{
  font-weight: 700;
}


.row{
	height:100vh;
}

.inherit-row{
	height:auto !important;
}

h1, h2, h3, h4, h5, h6, p{
	font-weight: 200;
}

.ml-xs-0{
  @media (max-width: 776px){
    margin-left:0px !important;
  }
}

.w-md-25{
	@media (min-width: 776px){
    width:25% !important;
	}
}

.w-xs-100{
  @media (max-width: 776px){
    width:100% !important;
  }
}

.mb-sm-4{
	@media (max-width: 776px){
    margin-bottom: 10px !important;
	}
}

.media-margin-top{
	@media (max-width: 776px){
    margin-top:130px !important;
	}
}

.max-height-media{
  @media (max-width: 876px){
    max-height:45% !important;
  }
}
.max-height-media svg{
  @media (max-width: 876px){
    max-height:20% !important;
  }
}

.login-perso{
  @media (max-width: 1176px){
    margin-right: -150px;
  }
  @media (max-width: 976px){
    margin-right: -250px;
  }
}

.perso-input-login{
  border:0px solid transparent;
  border-bottom: 2px solid #3459B5AA;
  border-radius: 0px !important;
}

.perso-input-login:hover{
  border-bottom: 2px solid #3459B5;
}

.rounded-button{
  border-radius: 30px !important;
}

.lg-input{
  height:50px !important;
  background-color: #FFFFFFAA !important;
  border-color: transparent !important;
}

.vertical {
  writing-mode: vertical-rl;
  text-orientation: sideways;
}

.bg-blue-light{
  background-color: #B5BED7;
}

.img_icone{

}

.big-text{
  font-size: 2.5rem;
  @media (max-width: 476px){
    font-size: 2rem !important;
  }
}

.missingDogs{
  position: fixed;
  top:0;
  left:8%;
  z-index:9999;
  @media (max-width: 876px){
    left:0 !important;
  }
}

.w-md-50{
  @media (min-width: 776px){
    width:50% !important;
  }
}


#form_cani div{
  margin-right:10px !important;
  padding-right: 10px !important;
}

// .big-text{
//   font-size: 4rem;
// }

.text-gradient {
  background: -webkit-linear-gradient(#743ad5, #d53a9d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-femelle {
  //background-image: linear-gradient(to top, #ff9a9e 0%, #fecfef 99%, #fecfef 100%);
  background: -webkit-linear-gradient(#ff9a9e, #fecfef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-male {
//  background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
  background: -webkit-linear-gradient(#e0c3fc, #8ec5fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-gradient{
    border-image: linear-gradient(to left, #743ad5 0%, #d53a9d 100%);
    border-image-slice: 1;
    border-width: 2px;
}

.border-gradient-green{
    border-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
    border-image-slice: 1;
    border-width: 2px;
}

.border-gradient-red{
  border-image: linear-gradient(to right, #ff8177 0%, #ff867a 0%, #ff8c7f 21%, #f99185 52%, #cf556c 78%, #b12a5b 100%);
  border-image-slice: 1;
  border-width: 2px;
}

.border-gradient-blue{
  border-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
  border-image-slice: 1;
  border-width: 4px;
}

.border-gradient-pink{
  border-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
  border-image-slice: 1;
  border-width: 4px;
}

.resize-little{
  height:50%;

  @media (max-width: 390px){
    min-height:55% !important;
    h3{
      font-size:1.2rem !important;
    }
  }
}

.hover-sidebar-mobile:hover{
  h3{
    color:$primary !important;
    text-decoration: none;
  }
  text-decoration: none;
  background-color: white;
}

.img_chien_small{
  overflow: hidden;
  border-radius: 80px;
  -webkit-border-radius:80px;
  -moz-border-radius:80px;
  width:150px;
  height:150px;
}

.img_chien_small img{
  min-width: 100%;
  height:100%;
}

.img_chien_small svg{
  height:auto;
  width:100px;
  border-radius: 50%;
}



.img_chien_tiny{
  overflow: hidden;
  border-radius: 60px;
  -webkit-border-radius:60px;
  -moz-border-radius:60px;
  width:30px;
  height:30px;
}

.img_chien_tiny img{
  min-width: 100%;
  height:100%;
}

.img_chien_tiny svg{
  height:auto;
  width:100px;
  border-radius: 50%;
}





.img_chien_big{
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius:50%;
  -moz-border-radius:50%;
  //width:250px;
 // height:250px;
/*  @media (max-width: 955px){
     height:130px !important;
     width:130px !important;
  }*/
}
/*
.img_chien_big img{
  min-width: 100%;
  height:100%;
}*/


.img_chien_big svg{
 // height:auto;
  //width:200px;
  @media (max-width: 955px){
     max-width:100px !important;
  }
  border-radius: 50%;
}

.w-bigger-infos{
  @media (max-width: 1055px){
     min-width:500px !important;
  }
}

.test-on{
  min-width:190px;
  border-radius: 30px;
  @media (max-width: 655px){
     min-width:150px !important;
  }
}

.bg-blue-light-2{
  background-color:#80B6E4;
}

.img_svg_small{
  height:auto;
  width:70px;
}

.img_chien_full img{
  max-width: 100%;
  width:auto;
  height:auto;
  max-height:70vh !important;
}

.img_chien_full{
  min-height:60vh !important;
}

.img_chien{
  height:auto;
  width:200px;
}

.img_chien img{
  height:auto;
  width:100%;
  border-radius: 60%;
}

.text-blue{
  color:#5875BE;
}

.bg-white #sortie_traineau_jourDeb{
  display: flex;
  justify-content: center;
}


.bg-perso{
  background-color: red;
}





.ui-autocomplete{
//	background-color: #AC5E5F6E;
background-color: #242833;
	border:0.5px solid grey;
	z-index:2;
	cursor:pointer;
	padding:0px;
	margin:0px;
//	padding-top: 5px;
	max-height: 140px;
	overflow-y: auto;
	/* prevent horizontal scrollbar */
	overflow-x: hidden;
	max-width:300px;
	width:300px;
	color:white;
	font-weight: 200;
}


	.ui-widget{
		padding:0px;
	}

	.ui-menu .ui-menu-item a{
		margin:0px;
		height:70px;
	}

.ui-menu li{
	border-bottom: 0.5px solid grey;
	padding:0px;
	padding-bottom:10px;
	margin:0px;
	transition: all 0.1s ease-in-out;
}

.ui-menu{
	padding:0px;
}

.ui-menu ul{
	padding:0px;
}

.ui-state,.ui-widget-content .ui-state,.ui-widget-header .ui-state,a.ui-button,.ui-button,.ui-state-active.ui-button{
	transition: all 0.1s ease-in-out;
	padding:15px;
}

.ui-widget-content li{
	padding:16px;
}

.ui-widget-content li:hover {
	background-color:$primary;
}


.radio-perso label{
  margin-right:10px;
  padding:2px;
}

.team-hover{
  transition: 0.2s ease-in-out;
  border-radius: 10px;
}

.text-hover:hover{
  text-decoration: none;
}

.team-hover:hover{
  text-decoration: none;
  background-image: linear-gradient(120deg, #e0c3fcA0 0%, #8ec5fcA0 100%);
}

.h-perso-120{
  height:70px;
}

.bg-blue-gradient {
  background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);
}

.bg-pink{
  background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
}

.bg-pink-light{
  background-image: linear-gradient(45deg, #ff9a9e4A 0%, #fad0c44A 99%, #fad0c44A 100%);
}

.opacity-5{
  opacity: 0.5;
}

.h-20{
  height:5px;
}

.little-text{
  font-size:10px;
}

.medium-text{
  font-size:0.7rem;
}

@import url('https://fonts.googleapis.com/css?family=Raleway:&display=swap');
@import url('https://fonts.googleapis.com/css?family=Vibur:&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');



.bg-badge-primary{
  background-color:#4248742A;
}

.font-special{
  //font-family: 'Raleway', sans-serif;
  font-family: 'Noto Sans', sans-serif;
  font-size: 3rem;
  color:$primary;
  opacity: 0.6;
   @media (max-width: 1000px){
      font-size: 2.5rem !important;
   }
   @media (max-width:500px){
      font-size: 1.4rem !important;
   }
}

.height-calendar{
  height:100vh;
  @media (max-width: 900px){
     height:50vh;
  }
}

.hover-sidebar p{
  display:block;
  position: fixed;
  z-index:-10;
  opacity:0;
  border-radius: 50%;
  color:white;
  transition: 0.7s ease-in-out;
  -webkit-transition:0.7s ease-in-out;
}
.hover-sidebar{
  border:1px solid transparent;
  width:auto;
  transition: 0.7s ease-in-out;
  padding:10px;
}
.hover-sidebar:hover{
  border:1px solid #293369;
  background-color: #293369; //THEEEE
  border-radius:40px;
  p{
    opacity: 1;
    margin-left:-45px;
    position: fixed;
    z-index:999;
  }
}

.hover-sidebar-spe{
  transition: 0.4s ease-in-out;
}

.hover-sidebar-spe h1{
  transition: 0.4s ease-in-out;
  text-decoration: none !important;
}

.hover-sidebar-spe:hover{
  background-color: #FFFFFF40;
  text-decoration: none !important;
  box-shadow: 3px 2px 2px black;
  h1{
    text-shadow: 3px 2px 2px black;
    text-decoration: none !important;
  }
}



//test police neon

.bg-login{

  background-color: dark;

}

.logo {
  text-align: center;
  width: 65%;
  height: 250px;
  margin: auto;
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
   user-select: none;

   @media (max-width: 776px){
      height:180px !important;
   }
}

.logo b{
  font: 400 19vh "Muli";
  color: #fee;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #79C1F2, 0 0 0.5em #5064F2, 0 0 0.1em #4243F2, 0 10px 3px #000;
  @media (max-width: 776px){
     font: 400 13vh "Muli";
  }
}
.logo b span{
  animation: blink linear infinite 2s;
}
.logo b span:nth-of-type(2){
  animation: blink linear infinite 3s;
}
@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit;
  }
  79%{
     color: #333;
  }
  80% {

    text-shadow: none;
  }
  81% {
    color: inherit;
    text-shadow: inherit;
  }
  82% {
    color: #333;
    text-shadow: none;
  }
  83% {
    color: inherit;
    text-shadow: inherit;
  }
  92% {
    color: #333;
    text-shadow: none;
  }
  92.5% {
    color: inherit;
    text-shadow: inherit;
  }
}


/* follow me @nodws */
#btn-twtr{
  clear:both;
  color:#fff;
  border:2px solid;
  border-radius:3px;
  text-align:center;
  text-decoration:none;
  display:block;
  font-family:sans-serif;
  font-size:14px;
  width:13em;
  padding:5px 10px;
  font-weight:600;
  position:absolute;
  bottom:120px;
  left:0;
  right:0;
  margin:0 auto;
  background:rgba(0,0,0,0.2);
  box-shadow:0 0 0px 3px rgba(0,0,0,0.2);
  opacity:0.4
}#btn-twtr:hover{color:#fff;opacity:1}

.perso-input input{
  display:none;
}

.perso-input label{
  padding:8px;
  margin:10px;
  border:1px solid transparent;
  border-radius:13px;
}

.perso-input label:hover{
  cursor: pointer;
  border:1px solid $primary;
  color:$primary;
}

.perso-input input:checked+label{
  border:1px solid $primary;
  color:$primary;
}

.perso-input-white label:hover{
  cursor: pointer;
  border:1px solid white;
  color:white;
}

.perso-input-white input:checked+label{
  border:1px solid white;
  color:white;
}

.label-big{
  font-size: 1.3rem;
  color:$primary;
}

.nav-item-override{
  &.active{
    opacity:1 !important;
    background-color: transparent !important;
    color: $primary !important;
    font-size:1rem !important;
    margin-top: auto !important;
  }
  opacity:0.5 !important;
  border:0px solid transparent !important;
  color:grey !important;
  font-size:0.9rem !important;
  margin-top: auto !important;
}

.checked {
  color: orange;
}


.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.name_chien{
  @media (max-width: 400px){
     font-size: 2.5rem !important;
  }

}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}


.text-styled{
  font-size:0.72rem;
  font-weight:500 !important;
  text-transform:uppercase;
}

#add_app{
.ui-datepicker, .ui-widget{
	min-width:250px;
	background-color: white;
	padding:10px;
	color: $primary;
	display: flex;
	justify-content: around;
	flex-direction: column;
}
.ui-datepicker-calendar{
	min-width: 100%;
}
.ui-datepicker-header{
	display: flex;
	justify-content: around;
	flex-direction: row;
	color:black;
}
.ui-datepicker-next{
	cursor:pointer;

	margin-left: 10%;
}
.ui-datepicker-prev{
	cursor:pointer;

}
.ui-datepicker-title{
	margin-left:auto;
	margin-right:auto;
	min-width:70%;
  margin:10px;
	text-align: center;
}


.ui-state-active{
  color:$primary;
  font-weight:700 !important;
  margin:10px;
  border-bottom:1px solid $primary;
  opacity:1 !important;
}
.ui-state-default{
  color:$primary;
  font-weight:400;
  opacity:0.6;
  margin:10px;
  text-align: center;
}

.ui-datepicker-week-end{
  margin:10px;
  text-align: center;
}
}


#carouselExampleIndicators .carousel-control-prev-icon, #carouselExampleIndicators .carousel-control-next-icon{
  background-color:#424874A2;
  padding:10px !important;
}



.nav-link{
  opacity:0.7;
  border-color:transparent;
  border-bottom: 2px solid transparent;
  border-radius: 0px !important;
  background-color: transparent !important;
  &.active{
    opacity:1;
    border-bottom: 2px solid $primary !important;
    font-weight:700;
    color:$primary !important;
    background-color: transparent !important;
  }
}


.w-md-auto{
  @media (min-width: 900px) {
    width:auto !important;
    min-width:100px;
    max-width:200px;
  }
}

.success-modal{
  width:40%;
  min-width: 30%;
  background-color: #47b1ad;
  border-radius: 20px;
  margin-top:15px;
  right:0;
  margin-right:20px;
  color:white;
  @media (max-width: 900px) {
    width:90%;
    min-width: 90%;
    center:0;
  }
}






#datepicker-carnet{
.ui-datepicker, .ui-widget{
	min-width:250px;
	padding:10px;
	color: white;
	display: flex;
	justify-content: around;
	flex-direction: column;
}
.ui-datepicker-calendar{
	min-width: 100%;
}
.ui-datepicker-header{
	display: flex;
	justify-content: around;
	flex-direction: row;
	color:black;
}
.ui-datepicker-next{
	cursor:pointer;
  color:white;
  font-size:0.8rem;
  font-weight:bold;
	margin-left: 10%;
  margin-top: auto;
  margin-bottom: auto;
}
.ui-datepicker-prev{
	cursor:pointer;
  color:white;
  font-size:0.8rem;
  font-weight:bold;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
}
.ui-datepicker-title{
	margin-left:auto;
	margin-right:0;
	min-width:60%;
  margin:10px;
	text-align: right;
  color:white;
  font-size:1.2rem;
  font-weight:bold;
  @media (max-width: 950px){
    font-size:1rem;
    min-width:45%;
    max-width:50%;
  }
}

td{
  padding:13px;
  text-align: center;
}
th{
  padding:13px;
  text-align: center;
}

.ui-state-active{
  color:$primary !important;
  background-color: white;
  font-weight:700 !important;
  border-bottom:1px solid $primary;
  opacity:1 !important;
  padding:4px;
  @media (max-width: 950px){
    font-size:0.8rem !important;
    padding:2px !important;
  }
}
.ui-state-default{
  color:white;
  font-weight:400;
  opacity:0.6;
  padding:4px;
  text-align: center;
  @media (max-width: 950px){
    font-size:0.8rem !important;
    padding:2px !important;
  }
}

.ui-datepicker-week-end{
  margin:10px;
  text-align: center;
}
}

.text-entered{

  a{
    border-bottom:2px solid white !important;
    opacity:1 !important;
  }
}


.hover-td p:hover{
  background-color: white;
  color:$primary !important;
}

.selected-td p{
  background-color: white;
  color:$primary !important;
}

.todayDate p{
  border:1px solid white;
  opacity:1 !important;
}

.changeTextArea textarea{
  background-color: #FFFFFF27;
  border-color: transparent !important;
  color:white !important;
  min-height:130px;

  :focus{

  }
}

.changeTextArea textarea:focus{
  background-color: #FFFFFF27 !important;
}

.changeTextArea textarea::placeholder{
    color:white !important;
}



.displayText ul{
  margin-left:50px !important;
}

.displayText p{
  font-weight:500;
}



.newSelect{
  select,
select.form-control {
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 0;
  padding-right: 0\9;
  background-color: transparent !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEVmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmaP/QSjAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
  -webkit-background-size: 13px 13px;
          background-size: 13px;
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-box-shadow: inset 0 -1px 0 #dddddd;
  box-shadow: inset 0 -1px 0 #dddddd;
  font-size: 16px;
  line-height: 1.9;
}
select::-ms-expand,
select.form-control::-ms-expand {
  display: none;
}
select.input-sm,
select.form-control.input-sm {
  font-size: 12px;
}
select.input-lg,
select.form-control.input-lg {
  font-size: 17px;
}
select:focus,
select.form-control:focus {
  -webkit-box-shadow: inset 0 -2px 0 #55D2BC;
  box-shadow: inset 0 -2px 0 #55D2BC;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEUhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISF8S9ewAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
}
select[multiple],
select.form-control[multiple] {
  background: none;
}
/* CSS from Bootstrap End */
}


.newSelectDate{
  select{
    min-width:50px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}
